<template>
  <div class="popup-add-fact">
    <div class="popup-add-fact__wrapper">
      <Column class="grid-md-6 grid-lg-6">
        <p class="popup-add-fact__title">{{ $t('popup.add_fact.add_fact') }}</p>
        <p class="popup-add-fact__description">{{ $t('popup.add_fact.desc') }}</p>
        <SelectComponent id="cityId"
                         :label="$t('popup.common.point')"
                         itemKey="name"
                         :index="cityId"
                         v-on:change-index="changeIndexCity"
                         :array="$cities.data"/>
        <InputComponent component="Column"
                        v-model="factTitle"
                        :label="$t('popup.common.title')"
                        id="factTitle"/>
        <InputComponent id="factDescription"
                        v-model="factDescription"
                        :label="$t('popup.common.desc')"
                        type="text"
                        maxChars="700"
                        multiline/>
        <AddFile
          :params="{label: $t('popup.common.photos')}"
          v-on:change-preview="changePreview"
          v-on:change-file="changeFile"
        />
        <div>
          <Button color="main"
                  :disabled="disabled"
                  v-on:click.native="addFact">{{ $t('popup.add_fact.add_fact_2') }}
          </Button>
        </div>
      </Column>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PopupAddFact',
  data() {
    return {
      cityId: 3,
      factTitle: '',
      factDescription: '',
      files: [],
      previews: [],
      disabled: true,
    };
  },
  methods: {
    changeIndexCity(id) {
      this.cityId = id;
    },
    changeFile(file) {
      return file;
    },
    changePreview(preview) {
      this.previews = preview;
    },
    checkRequiredInfo() {
      this.disabled = !(this.factTitle.length > 0 && this.factDescription.length > 0);
    },
    previewsToString() {
      let string = '';
      this.previews.forEach((item, i, array) => {
        string += `${i === 0 ? '[' : ''}"${item}"${i !== array.length - 1 ? ',' : ']'}`;
      });
      return string;
    },
    addFact() {
      let fact = '?';
      if (this.factTitle) fact += `title=${this.factTitle}`;
      if (this.factDescription) fact += `&description=${this.factDescription}`;
      if (this.cityId) fact += `&city_id=${this.cityId}`;
      if (this.previews.length > 0) fact += `&images=${this.previewsToString()}`;
      this.$postAddFact(fact);
      this.factTitle = '';
      this.factDescription = '';
      this.cityId = 3;
    },
  },
  watch: {
    factTitle() {
      this.checkRequiredInfo();
    },
    factDescription() {
      this.checkRequiredInfo();
    },
  },
};
</script>
